import React from "react"
import {
  Container,
  Section,
  M,
  GrayBorder,
  H2BlackTitleMargin,
} from "../../Global"

import { UserPanelSection } from "./styles"

import Package from "../../Elements/Package"

import IVideoTemplate from "./types"

const UserPanelComponent = ({}: IVideoTemplate) => {
  return (
    <UserPanelSection>
      <Container>
        <M>
          <H2BlackTitleMargin>{"TWOJE PAKIETY"}</H2BlackTitleMargin>
        </M>
      </Container>
      <GrayBorder />

      <Container>
        <M>
          <Package />
          <Package />
          <Package />
        </M>
      </Container>
    </UserPanelSection>
  )
}

export default UserPanelComponent
