import styled from "styled-components"
import { TripleGrid } from "../../Global"

export const PackageWrapper = styled.div`
  margin: 50px 0 50px 0;
`

export const PackageGrid = styled.div`
  display: grid;
  grid-template-columns: 400px auto;
  grid-template-rows: 1fr;
  gap: 20px;
  grid-auto-flow: row;
  grid-template-areas: ". .";
`
export const DescVideosWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 100px;
  gap: 10px;
  grid-auto-flow: row;
  grid-template-areas: ". " ".";
`

export const RestVideosGrid = styled(TripleGrid)`
  gap: 15px;
`
