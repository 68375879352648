import React from "react"
import {
  PackageGrid,
  RestVideosGrid,
  DescVideosWrapper,
  PackageWrapper,
} from "./styles"
import IPackage from "./types"
import { H2BlackTitle, FlexBetween } from "../../Global"
import Button from "../../UI/Button"
import TrainingCard from "../TrainingCard"

const Package = ({}: IPackage) => {
  return (
    <PackageWrapper>
      <FlexBetween>
        <H2BlackTitle>TECHNIKI UDERZEŃ 1</H2BlackTitle>
        <Button type="landing" slug="/">
          WYKUP PAKIET
        </Button>
      </FlexBetween>
      <PackageGrid>
        <TrainingCard
          authorName={"AUTHOR NAME"}
          trainingTitle={"TECHNIKI UDERZEN"}
          authorPicture={"c.author.picture_icon"}
          trainingVideo={"c.video"}
          videoSlug={"c.video.slug"}
          iFrame={"c.video.iFrame"}
          isInChallenge={false}
          price={"c.video.price"}
          intensity={5}
          rating={"c.video.rating"}
          lenght={"c.video.lenght"}
          videoId={"c.video.id"}
          packageSlug={"c.packagee.slug"}
          hideInfo={true}
        />
        <DescVideosWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.{" "}
          </p>
          <RestVideosGrid>
            <TrainingCard
              authorName={"AUTHOR NAME"}
              trainingTitle={"TECHNIKI UDERZEN"}
              authorPicture={"c.author.picture_icon"}
              trainingVideo={"c.video"}
              videoSlug={"c.video.slug"}
              iFrame={"c.video.iFrame"}
              isInChallenge={false}
              price={"c.video.price"}
              intensity={5}
              rating={"c.video.rating"}
              lenght={"c.video.lenght"}
              videoId={"c.video.id"}
              packageSlug={"c.packagee.slug"}
              hideInfo={true}
              isOtherPackage={true}
            />
            <TrainingCard
              authorName={"AUTHOR NAME"}
              trainingTitle={"TECHNIKI UDERZEN"}
              authorPicture={"c.author.picture_icon"}
              trainingVideo={"c.video"}
              videoSlug={"c.video.slug"}
              iFrame={"c.video.iFrame"}
              isInChallenge={false}
              price={"c.video.price"}
              intensity={5}
              rating={"c.video.rating"}
              lenght={"c.video.lenght"}
              videoId={"c.video.id"}
              packageSlug={"c.packagee.slug"}
              hideInfo={true}
              isOtherPackage={true}
            />
            <TrainingCard
              authorName={"AUTHOR NAME"}
              trainingTitle={"TECHNIKI UDERZEN"}
              authorPicture={"c.author.picture_icon"}
              trainingVideo={"c.video"}
              videoSlug={"c.video.slug"}
              iFrame={"c.video.iFrame"}
              isInChallenge={false}
              price={"c.video.price"}
              intensity={5}
              rating={"c.video.rating"}
              lenght={"c.video.lenght"}
              videoId={"c.video.id"}
              packageSlug={"c.packagee.slug"}
              hideInfo={true}
              isOtherPackage={true}
            />
          </RestVideosGrid>
        </DescVideosWrapper>
      </PackageGrid>
    </PackageWrapper>
  )
}

export default Package
