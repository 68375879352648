import * as React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Global/Head"
import UserPanelComponent from "../components/Sections/UserPanel"

const UserPanel = (props: any) => {
  return (
    <Layout hideLanding={true}>
      <Head
        pageTitle="User Panel" //todo
        description="" //todo
        keywords="trening online, platforma treningowa online, video trening" //todo
      />
      <UserPanelComponent />
    </Layout>
  )
}

export default UserPanel
